<template>
  <div>
    <template v-if="layout == 0">
      <p>付款方式</p>
      <v-radio-group
        v-if="providerHasPaymentType"
        v-model="paymentTypeSelected"
        row
      >
        <v-radio
          v-for="(item, index) in providerInfo.payment_type"
          :key="index"
          :label="$t('car.payment_type.' + item)"
          :value="item"
        ></v-radio>
      </v-radio-group>
    </template>
    <template v-else-if="layout == 1">
      <v-card>
        <v-card-title>
          選擇付款方式
        </v-card-title>

        <v-card-text v-if="providerHasPaymentType" class="pt-5">
          <v-btn
            v-for="item in providerInfo.payment_type"
            :key="item"
            block
            x-large
            color="primary"
            class="mb-4"
            @click="toPayBtn(item)"
            >{{ $t(`data.payment_type.${item}`) }}</v-btn
          >
        </v-card-text>
      </v-card>
    </template>
    <div v-if="ecpayHtmlArea" v-html="ecpayHtml"></div>
  </div>
</template>

<script>
import paymentTypeForB2C from "@/components/payment/mixins/paymentTypeForB2C.js";
import provider from "modules/provider"

export default {
  mixins: [paymentTypeForB2C],
  props: {
    layout: {
      type: Number,
      default: 0,
    },
    newOrderId: String,
    data: Object,
    beforePay: {
      type: Function,
    },
    afterPayForEcPay: {
      type: Function,
    },
    ecpayHtmlArea: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    ecpayHtml: "",
    paymentTypeSelected: "cash_on_delivery",
    beforePayParams: {},
    paymentTypeList: [],
    providerInfo: {},
  }),
  computed: {
    providerHasPaymentType() {
      return Object.keys(this.providerInfo).includes('payment_type')
    },
    allPaymentTypes() {
      return this.cashPaymentTypes.concat(this.thirdPartyPaymentTypes);
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    orderId() {
      return this.data ? this.data.order_no : null;
    },
  },
  methods: {
    async getProviderInfoApi(params) {
      const res = await this.$api.collection.publicApi.getProviderInfo(
        this.providerId
      );
      const paymentTypes = res.payment_type
      if (paymentTypes.includes("pay_first")) {
        res.payment_type = paymentTypes.includes("atm")
          ? paymentTypes.filter(type => type !== "pay_first")
          : paymentTypes.map(type => type === "pay_first" ? "atm" : type)
      }
      this.providerInfo = res
      this.paymentTypeSelected = res.payment_type[0]
    },
    toPayBtn(type) {
      this.paymentTypeSelected = type == "atm" ? "pay_first" : type;
      this.beforePayParams = {
        paymentType: this.paymentTypeSelected,
      };
      this.toPay();
    },
    async toPay() {
      if (!this.providerId || !this.orderId) {
        this.$snotify.error("付款失敗");
        this.$store.dispatch("loading/close");
        return false;
      }

      if (this.paymentTypeSelected == "cash_on_delivery") {
        this.$router.replace({
          name: "store-order-detail",
          params: { providerId: this.providerId, orderId: this.orderId },
        });
      } else {
        this.$store.dispatch("loading/active");
        try {
          if (typeof this.beforePay == "function") {
            await this.beforePay(this.beforePayParams);
          }
          const params = {
            orderId: this.orderId,
            local: false,
          };
          if (this.paymentTypeSelected == "linepay") {
            const paymentLink = await this.$api.collection.orderApi.paymentByLinePay(
              params
            );

            if (paymentLink.web) {
              window.location.replace(paymentLink.web);
            }
          } 
          else if (this.paymentTypeSelected == "atm" || this.paymentTypeSelected == "pay_first") {
            // 後端 ATM 延續使用原本的 PAYMENT_TYPE_PAY_FIRST=pay_first
            this.$store.dispatch("loading/close");
            this.$router.push({ name: "atm-payment" , params: { providerId: this.providerId }, query: { orderId: this.orderId }});
          }
          else if (this.paymentTypeSelected == "ecpay") {
            const paymentLink = await this.$api.collection.orderApi.paymentByECPay(
              params
            );

            if (typeof this.afterPayForEcPay == "function") {
              this.afterPayForEcPay(paymentLink.html);
            } else {
              this.ecpayHtml = paymentLink.html;

              this.$nextTick(() => {
                document.getElementById("ecpay-form").submit();
              });
            }
          }
        } catch (error) {
          console.error(error);
          this.$snotify.error("付款失敗");
          this.$store.dispatch("loading/close");
        }
      }
    },
  },
  mounted() {
    this.getProviderInfoApi();
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
